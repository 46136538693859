<template >

  <BaseContainer/>

</template>

<script>
import BaseContainer from './components/BaseContainer.vue'

export default {
  name: 'App',
  components: {
    BaseContainer,
}
}
</script>

<style>

</style>
